<template>
    <div class="StaffEdit">
        <el-card shadow="never" style="margin-top: 8px">
            <el-page-header @back="goBack" />
        </el-card>
        <el-card shadow="never" style="margin-top: 8px">
            <el-form ref="form" :model="form" label-width="80px" size="small">
                <el-form-item label="员工类型">
                    <span>{{ staff.type ? '业务型' : '管理型' }}</span>
                </el-form-item>
                <el-form-item label="用户名">
                    <span>{{ staff.username }}</span>
                </el-form-item>
                <el-form-item label="姓名">
                    <span>{{ staff.realName }}</span>
                </el-form-item>
                <el-form-item label="性别">
                    <span>{{ staff.sex ? '女' : '男' }}</span>
                </el-form-item>
                <el-form-item label="年龄">
                    <el-input v-model="staff.age" />
                </el-form-item>
                <el-form-item label="手机号">
                    <span>{{ staff.mobile }}</span>
                </el-form-item>
                <el-form-item label="身份证号">
                    <span>{{ staff.idNumber }}</span>
                </el-form-item>
                <el-form-item label="部门类型">
                    <span>{{ staff.deptType | type }}</span>
                </el-form-item>
            </el-form>
        </el-card>
    </div>
</template>

<script>
import UrlUtils from '../../../../js/UrlUtils';

export default {
    name: 'StaffInfo',
    props: ['code'],
    data() {
        return {
            staff: {},
        };
    },
    mounted() {
        UrlUtils.QueryRemote(this, '/system/staff/info_c/' + this.$route.params.code, (rst) => {
            this.staff = rst;
        });
    },
    filters: {
        type(t) {
            if (t == 1) {
                return '总部';
            }
            if (t == 2) {
                return '配送中心';
            }
            if (t == 3) {
                return '门店';
            }
        },
    },
};
</script>

<style scoped>
.el-input {
    width: auto;
}
</style>
